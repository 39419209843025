import xhr from './xhr/';
/**
 * 会员管理相关的 API
 */

class MemberService {
	/**
	 * 会员基本信息列表
	 */
	getBase(data) {
		return xhr({
			method: 'get',
			url: '/member/base',
			params: data,
		});
	}

	/**
	 * 会员搜索查询
	 */
	getMemberQuery(data) {
		return xhr({
			method: 'get',
			url: '/member/query',
			params: data,
		});
	}

	/*
	 * 删除会员信息
	 */
	deleteMember(ids) {
		return xhr({
			method: 'post',
			url: '/member/del',
			params: ids,
		});
	}

	/**
	 * 新建会员档案
	 */
	createMember(data) {
		return xhr({
			method: 'post',
			url: '/member/add',
			params: data,
		});
	}

	// 顶部公共 会员基本信息
	getinfo(data) {
		return xhr({
			method: 'get',
			url: '/base/info',
			params: data,
		});
	}

	// 会员个人数据获取一览查询
	total(data) {
		return xhr({
			method: 'get',
			url: '/member/total',
			params: data,
		});
	}

	// 会员信息详情
	detail(id) {
		return xhr({
			method: 'get',
			url: '/member/detail',
			params: id,
		});
	}

	// 编辑修改会员档案
	update(data) {
		return xhr({
			method: 'post',
			url: '/member/update',
			params: data,
		});
	}

	// 获取最近一条推送记录
	sendQuery(data) {
		return xhr({
			method: 'get',
			url: '/send/query',
			params: data,
		});
	}

	// 推送成药与要素
	sendDrug(data) {
		return xhr({
			method: 'post',
			url: '/drug/send',
			params: data,
		});
	}

	// 膳食记录推送
	sendFood(data) {
		return xhr({
			method: 'post',
			url: '/food/send',
			params: data,
		});
	}

	// 运动记录推送
	sendSport(data) {
		return xhr({
			method: 'post',
			url: '/sport/send',
			params: data,
		});
	}

	// 训练记录推送
	sendTrans(data) {
		return xhr({
			method: 'post',
			url: '/trans/send',
			params: data,
		});
	}

	// 疾病信息获取
	sickMessage() {
		return xhr({
			method: 'get',
			url: '/sick/message/list',
		});
	}

	// 会员疾病信息详情
	sicknessQuery(data) {
		return xhr({
			method: 'get',
			url: '/sickness/query',
			params: data,
		});
	}

	// 提交会员疾病信息
	sicknessAdd(data) {
		return xhr({
			method: 'post',
			url: '/sickness/add',
			params: data,
		});
	}

	// 删除会员疾病信息
	sicknessDel(data) {
		return xhr({
			method: 'post',
			url: '/sickness/del',
			params: data,
		});
	}

	// 会员手术历史列表及详情查询
	operationQuery(data) {
		return xhr({
			method: 'get',
			url: '/operation/query',
			params: data,
		});
	}

	// 提交修改手术历史信息
	operationAdd(data) {
		return xhr({
			method: 'post',
			url: '/operation/add',
			params: data,
		});
	}

	// 删除会员手术历史
	operationDel(data) {
		return xhr({
			method: 'post',
			url: '/operation/del',
			params: data,
		});
	}

	// 会员主诉及详情查询
	mainQuery(data) {
		return xhr({
			method: 'get',
			url: '/main/query',
			params: data,
		});
	}

	// 删除会员主诉
	mainDel(data) {
		return xhr({
			method: 'post',
			url: '/main/del',
			params: data,
		});
	}

	// 提交修改会员主诉信息
	mainAdd(data) {
		return xhr({
			method: 'post',
			url: '/main/add',
			params: data,
		});
	}

	// 遗传病查询
	historyQuery(data) {
		return xhr({
			method: 'get',
			url: '/history/query',
			params: data,
		});
	}

	// 提交修改遗传
	historyAdd(data) {
		return xhr({
			method: 'post',
			url: '/history/add',
			params: data,
		});
	}

	// 查询会员血压
	bloodQuery(data) {
		return xhr({
			method: 'get',
			url: '/blood/query',
			params: data,
		});
	}

	// 删除血压数据
	bloodDelete(data) {
		return xhr({
			method: 'post',
			url: '/blood/delete',
			params: data,
		});
	}

	// id对应某条血压详情
	bloodDetail(data) {
		return xhr({
			method: 'get',
			url: '/blood/detail',
			params: data,
		});
	}

	// 增加血压数据
	bloodAdd(data) {
		return xhr({
			method: 'post',
			url: '/add/blood',
			params: data,
		});
	}

	// 修改血压数据
	bloodEdit(data) {
		return xhr({
			method: 'post',
			url: '/blood/edit',
			params: data,
		});
	}

	// 搜索会员血糖数据
	sugarQuery(data) {
		return xhr({
			method: 'get',
			url: '/sugar/query',
			params: data,
		});
	}

	// 删除血糖数据
	sugarDelete(data) {
		return xhr({
			method: 'post',
			url: '/sugar/delete',
			params: data,
		});
	}

	// 增加一条血糖数据
	sugarAdd(data) {
		return xhr({
			method: 'post',
			url: '/sugar/add',
			params: data,
		});
	}

	// id对应某条血糖详情
	sugarDetail(data) {
		return xhr({
			method: 'get',
			url: '/sugar/detail',
			params: data,
		});
	}

	// 修改血糖数据
	sugarEdit(data) {
		return xhr({
			method: 'post',
			url: '/sugar/edit',
			params: data,
		});
	}

	// 检测项目子类列表
	checkProject() {
		return xhr({
			method: 'get',
			url: '/check/project',
		});
	}

	// 时间段查询尿酸
	uricacidQuery(data) {
		return xhr({
			method: 'get',
			url: '/uricacid/query',
			params: data,
		});
	}

	// 尿酸数据删除
	uricacidDelete(data) {
		return xhr({
			method: 'post',
			url: '/uricacid/delete',
			params: data,
		});
	}

	// 添加一条尿酸数据
	uricacidAdd(data) {
		return xhr({
			method: 'post',
			url: '/uricacid/add',
			params: data,
		});
	}

	// id对应某条尿酸详情
	uricacidDetail(data) {
		return xhr({
			method: 'get',
			url: '/uricacid/detail',
			params: data,
		});
	}

	// 尿酸修改
	uricacidEdit(data) {
		return xhr({
			method: 'post',
			url: '/uricacid/edit',
			params: data,
		});
	}

	// 血脂数据查询
	cholesterinQuery(data) {
		return xhr({
			method: 'get',
			url: '/cholesterin/query',
			params: data,
		});
	}

	// 删除血脂数据
	cholesterinDelete(data) {
		return xhr({
			method: 'post',
			url: '/cholesterin/delete',
			params: data,
		});
	}

	// 增加一条血脂
	cholesterinAdd(data) {
		return xhr({
			method: 'post',
			url: '/cholesterin/add',
			params: data,
		});
	}

	// id对应某条血脂详情
	cholesterinDetail(data) {
		return xhr({
			method: 'get',
			url: '/cholesterin/detail',
			params: data,
		});
	}

	// 修改血脂数据
	cholesterinEdit(data) {
		return xhr({
			method: 'post',
			url: '/cholesterin/edit',
			params: data,
		});
	}

	// 时间段查询上传的腰围
	heigthQuery(data) {
		return xhr({
			method: 'get',
			url: '/height/query',
			params: data,
		});
	}

	// 删除身高腰围数据
	heightDelete(data) {
		return xhr({
			method: 'post',
			url: '/height/delete',
			params: data,
		});
	}

	// 增加一条身高腰围数据
	heightAdd(data) {
		return xhr({
			method: 'post',
			url: '/height/add',
			params: data,
		});
	}

	// id对应某条身高腰围详情
	heightDetail(data) {
		return xhr({
			method: 'get',
			url: '/height/detail',
			params: data,
		});
	}

	// 修改身高腰围数据
	heightEdit(data) {
		return xhr({
			method: 'post',
			url: '/height/edit',
			params: data,
		});
	}

	// 搜索查询身体成分数据
	bodyQuery(data) {
		return xhr({
			method: 'get',
			url: '/body/query',
			params: data,
		});
	}

	// 添加人体成分
	bodyAdd(data) {
		return xhr({
			method: 'post',
			url: '/body/add',
			params: data,
		});
	}

	// 删除人体成分数据
	bodyDelete(data) {
		return xhr({
			method: 'post',
			url: '/body/del',
			params: data,
		});
	}

	// id对应某条身体成分详情
	bodyDetail(data) {
		return xhr({
			method: 'get',
			url: '/body/detail',
			params: data,
		});
	}

	// 修改人体成分数据
	bodyUpdate(data) {
		return xhr({
			method: 'post',
			url: '/body/update',
			params: data,
		});
	}

	// 握力查询
	gripQuery(data) {
		return xhr({
			method: 'get',
			url: '/grip/query',
			params: data,
		});
	}

	// 握力数据添加
	gripAdd(data) {
		return xhr({
			method: 'post',
			url: '/grip/add',
			params: data,
		});
	}

	// 握力数据删除
	gripDelete(data) {
		return xhr({
			method: 'post',
			url: '/grip/del',
			params: data,
		});
	}

	// id对应某条握力数据详情
	gripDetail(data) {
		return xhr({
			method: 'get',
			url: '/grip/detail',
			params: data,
		});
	}

	// 握力数据修改
	gripUpdate(data) {
		return xhr({
			method: 'post',
			url: '/grip/update',
			params: data,
		});
	}

	// 肺活量数据查询
	capacityQuery(data) {
		return xhr({
			method: 'get',
			url: '/capacity/query',
			params: data,
		});
	}

	// 添加肺活量数据
	capacityAdd(data) {
		return xhr({
			method: 'post',
			url: '/capacity/add',
			params: data,
		});
	}

	// 肺活量数据删除
	capacityDelete(data) {
		return xhr({
			method: 'post',
			url: '/capacity/del',
			params: data,
		});
	}

	// id对应某条肺活量详情
	capacityDetail(data) {
		return xhr({
			method: 'get',
			url: '/capacity/detail',
			params: data,
		});
	}

	// 肺活量数据修改
	capacityUpdate(data) {
		return xhr({
			method: 'post',
			url: '/capacity/update',
			params: data,
		});
	}

	// 血氧数据查询
	spoQuery(data) {
		return xhr({
			method: 'get',
			url: '/spo/query',
			params: data,
		});
	}

	// 添加血氧数据
	spoAdd(data) {
		return xhr({
			method: 'post',
			url: '/spo/add',
			params: data,
		});
	}

	// 血氧数据删除
	spoDelete(data) {
		return xhr({
			method: 'post',
			url: '/spo/del',
			params: data,
		});
	}

	// id对应某条血氧详情
	spoDetail(data) {
		return xhr({
			method: 'get',
			url: '/spo/detail',
			params: data,
		});
	}

	// 血氧数据修改
	spoUpdate(data) {
		return xhr({
			method: 'post',
			url: '/spo/update',
			params: data,
		});
	}

	// 平衡数据查询
	balancedQuery(data) {
		return xhr({
			method: 'get',
			url: '/balanced/query',
			params: data,
		});
	}

	// 增加平衡数据
	balancedAdd(data) {
		return xhr({
			method: 'post',
			url: '/balanced/add',
			params: data,
		});
	}

	// 删除平衡数据
	balancedDelete(data) {
		return xhr({
			method: 'post',
			url: '/balanced/del',
			params: data,
		});
	}

	// id对应某条平衡数据详情
	balancedDetail(data) {
		return xhr({
			method: 'get',
			url: '/balanced/detail',
			params: data,
		});
	}

	// 修改平衡数据
	balancedUpdate(data) {
		return xhr({
			method: 'post',
			url: '/balanced/update',
			params: data,
		});
	}

	// 查询运动记录
	sportsQuery(data) {
		return xhr({
			method: 'get',
			url: '/sports/query',
			params: data,
		});
	}

	// 增加会员运动数据
	sportsAdd(data) {
		return xhr({
			method: 'post',
			url: '/sports/add',
			params: data,
		});
	}

	// 运动数据删除
	sportsDel(data) {
		return xhr({
			method: 'post',
			url: '/sports/del',
			params: data,
		});
	}

	// id对应某条运动记录详情
	sportsDetail(data) {
		return xhr({
			method: 'get',
			url: '/sports/detail',
			params: data,
		});
	}

	// 修改某一条会员运动数据
	sportsUpdate(data) {
		return xhr({
			method: 'post',
			url: '/sports/update',
			params: data,
		});
	}

	// 训练记录查询
	transQuery(data) {
		return xhr({
			method: 'get',
			url: '/trans/query',
			params: data,
		});
	}

	// 宅客训练记录
	zhaikeQuery(data) {
		return xhr({
			method: 'get',
			url: '/zhaike_trans/query',
			params: data,
		});
	}

	// 训练报表
	trainReportList(data) {
		return xhr({
			method: 'get',
			url: '/member/trans/report/list',
			params: data,
		});
	}

	// 生成训练报告
	makeTrainReport(data) {
		return xhr({
			method: 'post',
			url: '/member/trans/report/make',
			params: data,
		});
	}

	// 训练报告详情
	trainReportDetail(data) {
		return xhr({
			method: 'get',
			url: '/member/trans/report/xq',
			params: data,
		});
	}

	// 训练记录增加
	transAdd(data) {
		return xhr({
			method: 'post',
			url: '/trans/add',
			params: data,
		});
	}

	// 删除训练记录
	transDel(data) {
		return xhr({
			method: 'post',
			url: '/trans/del',
			params: data,
		});
	}

	// id对应训练记录详情
	transDetail(data) {
		return xhr({
			method: 'get',
			url: '/trans/detail',
			params: data,
		});
	}

	// 修改训练记录
	transUpdate(data) {
		return xhr({
			method: 'post',
			url: '/trans/update',
			params: data,
		});
	}

	/*
	 * 成药与要素信息查询
	 */
	queryDrug(data) {
		return xhr({
			method: 'get',
			url: '/drug/query',
			params: data,
		});
	}

	/*
	 * 成药与要素名称查询
	 */
	listDrugName() {
		return xhr({
			method: 'get',
			url: '/drug/name/detail',
		});
	}

	/*
	 * 药品分类查询查询
	 */
	listDrugType() {
		return xhr({
			method: 'get',
			url: '/drug/type',
		});
	}

	/*
	 * id对应成药与要素详情
	 */
	detailDrug(data) {
		return xhr({
			method: 'get',
			url: '/drug/detail',
			params: data,
		});
	}

	// 服药服从性列表
	complianceList() {
		return xhr({
			method: 'get',
			url: '/compliance/list',
		});
	}

	/*
	 * 成药与要素信息增加
	 */
	addDrug(data) {
		return xhr({
			method: 'post',
			url: '/drug/add',
			params: data,
		});
	}

	/*
	 * 成药与要素信息修改
	 */
	updateDrug(data) {
		return xhr({
			method: 'post',
			url: '/drug/update',
			params: data,
		});
	}

	/*
	 * 成药与要素信息删除
	 */
	delDrug(data) {
		return xhr({
			method: 'post',
			url: '/drug/del',
			params: data,
		});
	}

	/*
	 * 检测图片查询查询
	 */
	queryPicCheck(data) {
		return xhr({
			method: 'get',
			url: '/check/query',
			params: data,
		});
	}

	/*
	 * 检测图片分类查询
	 */
	listPicType() {
		return xhr({
			method: 'get',
			url: '/picture/list',
		});
	}

	/*
	 * id对应检测图片详情
	 */
	detailPicCheck(data) {
		return xhr({
			method: 'get',
			url: '/check/detail',
			params: data,
		});
	}

	/*
	 * 检测图片增加
	 */
	addPicCheck(data) {
		return xhr({
			method: 'post',
			url: '/check/add',
			params: data,
		});
	}

	/*
	 * 检测图片修改
	 */
	updatePicCheck(data) {
		return xhr({
			method: 'post',
			url: '/check/update',
			params: data,
		});
	}

	/*
	 * 检测图片删除
	 */
	delPicCheck(data) {
		return xhr({
			method: 'post',
			url: '/check/del',
			params: data,
		});
	}

	/*
	 * 膳食记录查询
	 */
	queryFood(data) {
		return xhr({
			method: 'get',
			url: '/food/query',
			params: data,
		});
	}

	/*
	 * 食物列表
	 */
	listFood() {
		return xhr({
			method: 'get',
			url: '/food/list',
		});
	}

	/*
	 * 膳食记录详情
	 */
	detailFood(data) {
		return xhr({
			method: 'get',
			url: '/food/detail',
			params: data,
		});
	}

	/*
	 * 膳食记录 时间，类型查询详情
	 */
	queryFoodDetail(data) {
		return xhr({
			method: 'get',
			url: '/food/time',
			params: data,
		});
	}

	/*
	 * 膳食列表和膳食数量
	 */
	queryFoodDetailData(data) {
		return xhr({
			method: 'get',
			url: '/food/detail/query',
			params: data,
		});
	}

	/*
	 * 膳食记录增加
	 */
	addFood(data) {
		return xhr({
			method: 'post',
			url: '/food/add',
			params: data,
		});
	}

	/*
	 * 膳食记录修改
	 */
	updateFood(data) {
		return xhr({
			method: 'post',
			url: '/food/update',
			params: data,
		});
	}

	/*
	 * 膳食记录删除
	 */
	delFood(data) {
		return xhr({
			method: 'post',
			url: '/food/del',
			params: data,
		});
	}

	// 获取会员等级类型
	getMemberType(data) {
		return xhr({
			method: 'post',
			url: '/member/type',
			params: data,
		});
	}

	// 查询已购卡系记录
	getCardQuery(data) {
		return xhr({
			method: 'get',
			url: '/card/query',
			params: data,
		});
	}

	// 增加一条已购卡系记录
	addCard(data) {
		return xhr({
			method: 'post',
			url: '/card/add',
			params: data,
		});
	}

	// 删除已购卡记录
	deleteCard(data) {
		return xhr({
			method: 'post',
			url: '/card/delete',
			params: data,
		});
	}

	// 修改已购卡数据
	editCard(data) {
		return xhr({
			method: 'post',
			url: '/card/edit',
			params: data,
		});
	}

	// 获取所卡系等级信息
	getCardList() {
		return xhr({
			method: 'get',
			url: '/card/list',
		});
	}

	// web查询生理数据
	KHeatQuery(params) {
		return xhr({
			method: 'get',
			url: '/web/KHeat/query',
			params,
		});
	}

	// web删除生理数据
	KHeatDelete(params) {
		return xhr({
			method: 'post',
			url: '/web/KHeat/delete',
			params,
		});
	}

	// web运动数据查询
	watchSportQuery(params) {
		return xhr({
			method: 'get',
			url: '/web/watch/sport/query',
			params,
		});
	}

	// web运动数据删除
	watchSportDelete(params) {
		return xhr({
			method: 'post',
			url: '/web/watch/sport/delete',
			params,
		});
	}

	// web睡眠数据删除
	watchSleepDelete(params) {
		return xhr({
			method: 'post',
			url: '/web/watch/sleep/delete',
			params,
		});
	}

	// web睡眠数据查询
	watchSleepQuery(params) {
		return xhr({
			method: 'get',
			url: '/web/watch/sleep/query',
			params,
		});
	}

	// web睡眠数据图表查询
	watchSleepChartQuery(params) {
		return xhr({
			method: 'get',
			url: '/web/watch/sleep/chartquery',
			params,
		});
	}

	// web一周概览
	watchWeekDetail(params) {
		return xhr({
			method: 'get',
			url: '/web/watch/week/detail',
			params,
		});
	}

	// web心电数据图表查询
	ECGChartQuery(params) {
		return xhr({
			method: 'get',
			url: '/web/watch/ecg/chartquery',
			params,
		});
	}

	// 查询、录入监测数据
	newMonitorData(params) {
		return xhr({
			method: 'post',
			url: '/autoplan/monitor',
			params,
		});
	}

	// 查询、录入监测数据
	newDiseaseData(params) {
		return xhr({
			method: 'post',
			url: '/autoplan/sick',
			params,
		});
	}

	// 查询、录入问卷数据
	newBehaviorData(params) {
		return xhr({
			method: 'post',
			url: '/autoplan/wj',
			params,
		});
	}

	/**
	 * 心脑无忧
	 */
	// 获取行为列表
	getBehaviorList() {
		return xhr({
			method: 'get',
			url: '/xnwy/xwlb',
		});
	}

	// 获取记录列表
	getECGList(params) {
		return xhr({
			method: 'get',
			url: '/xnwy/jllb',
			params,
		});
	}

	// 提交数据
	submitECGData(params) {
		return xhr({
			method: 'post',
			url: '/xnwy/sj/tj',
			params,
		});
	}

	// 获取详情
	getECGData(id) {
		return xhr({
			method: 'get',
			url: '/xnwy/jlxq',
			params: {
				jl_id: id,
			},
		});
	}

	// 获取评估报告
	getECGEvaluate(params) {
		return xhr({
			method: 'get',
			url: '/xnwy/pdf/page',
			params,
		});
	}

	// 获取评估报告
	getDangerSolution(data) {
		return xhr({
			method: 'get',
			url: '/xnwy/fxgb',
			params: {
				data,
			},
		});
	}

	// 会员详情 - 报告
	getReportList(params) {
		return xhr({
			method: 'post',
			url: '/bg/list',
			params,
		});
	}

	// 会员详情 - 报告
	getServiceStaffList(params) {
		return xhr({
			method: 'post',
			url: '/bg/fwyg',
			params,
		});
	}

	// 会员详情 - 报告类型
	getReportType(params) {
		return xhr({
			method: 'post',
			url: '/bg/lx',
			params,
		});
	}

	// 会员详情 - 添加报告
	addReport(data) {
		return xhr({
			method: 'post',
			url: '/bg/add',
			params: data,
		});
	}

	// 会员详情 - 报告详情
	reportDetail(data) {
		return xhr({
			method: 'post',
			url: '/bg/detail',
			params: data,
		});
	}

	// 删除报告
	deleteReport(data) {
		return xhr({
			method: 'post',
			url: '/bg/delete',
			params: data,
		});
	}

	// 批量下载报告
	downloadReport(data) {
		return xhr({
			method: 'post',
			url: '/bg/pldown',
			params: data,
		});
	}

	// 上传
	uploadReport(data) {
		return xhr({
			isFile: true,
			method: 'post',
			url: '/check/photo/upload',
			params: data,
		});
	}

	// 课程列表
	getClassList(data) {
		return xhr({
			method: 'get',
			url: '/kecheng/list',
			params: data,
		});
	}

	// 课程活动
	getClassActivity() {
		return xhr({
			method: 'post',
			url: '/kecheng/huodong',
		});
	}

	// 课程活动
	getHealthDevice() {
		return xhr({
			method: 'post',
			url: '/kecheng/fjyq',
		});
	}

	// 新增课程
	addClass(data) {
		return xhr({
			method: 'post',
			url: '/kecheng/add',
			params: data,
		});
	}

	// 查看课程详情
	getClass(data) {
		return xhr({
			method: 'post',
			url: '/kecheng/xq',
			params: data,
		});
	}

	// 课程会员搜索
	kechengUser(data) {
		return xhr({
			method: 'get',
			url: '/kecheng/user',
			params: data,
		});
	}

	deleteClass(data) {
		return xhr({
			method: 'post',
			url: '/kecheng/delete',
			params: data,
		});
	}

	// 心肺耐力
	getMemberInfo(data) {
		return xhr({
			method: 'post',
			url: '/xfnl/head',
			params: data,
		});
	}

	// 保存 ABO 问卷
	handleABO(data) {
		return xhr({
			method: 'post',
			url: '/question/abo',
			params: data,
		});
	}

	// 心肺接口
	xf1(data) {
		return xhr({
			method: 'post',
			url: '/xfnl/chartdata1',
			params: data,
		});
	}

	xf2(data) {
		return xhr({
			method: 'post',
			url: '/xfnl/chartdata2',
			params: data,
		});
	}

	xf3(data) {
		return xhr({
			method: 'post',
			url: '/xfnl/chartdata3',
			params: data,
		});
	}

	xfTimer(data) {
		return xhr({
			method: 'post',
			url: '/xfnl/trans',
			params: data,
		});
	}

	// 报告列表（问卷）
	getReportsList(params) {
		return xhr({
			method: 'get',
			url: '/question/recordlist',
			params,
		});
	}

	// 心肺耐力客户基础数据
	postCustomerData(params) {
		return xhr({
			method: 'post',
			url: '/xfnl/slsj',
			params,
		});
	}

	// 监测数据列表
	getMemberMonitorList(params) {
		return xhr({
			method: 'post',
			url: '/monitor/body/query',
			params,
		});
	}

	// 监测数据 - 查询
	getMemberMonitor(params) {
		return xhr({
			method: 'post',
			url: '/monitor/body/member',
			params,
		});
	}

	// 监测数据 - 修改
	modifyMemberMonitor(params) {
		return xhr({
			method: 'post',
			url: '/monitor/body/modify',
			params,
		});
	}

	// 获取员工列表
	getStaffList(params) {
		return xhr({
			method: 'post',
			url: '/member/staff/list',
			params,
		});
	}

	// 修改服务员工
	modifyMember(params) {
		return xhr({
			method: 'post',
			url: '/member/staff/modify',
			params,
		});
	}

	// 批量修改所属门店
	modifyStoreBatch(params) {
		return xhr({
			method: 'post',
			url: '/member/plxg/branch',
			params,
		});
	}

	// 批量修改服务员工
	modifyStaffBatch(params) {
		return xhr({
			method: 'post',
			url: '/member/plxg/fwyg',
			params,
		});
	}

	// 批量修改顾客类型
	modifyTypeBatch(params) {
		return xhr({
			method: 'post',
			url: '/member/plxg/gklx',
			params,
		});
	}
}

export default new MemberService();
